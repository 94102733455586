import React from "react"
import Layout from "../components/layout"

const test = () => {
  return (
    <Layout>
      <h2>aaa</h2>
    </Layout>
  )
}

export default test
